import React, { Component }  from 'react';
import { P, PHead, Img } from "./BigBox";
import styled from "styled-components";
import { VerticalLine } from "./BigBox";

export const ImageDiv = styled.div`
margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
`;

export const Wrapper = styled.div`
    border-top: solid 5px #ed6a4e;
    background-color: white;
    margin-top: 2rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    width: 49%;
     @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

export interface SmallBoxInterface{
    headline: string;
    src: string;
    headlineText: string;
    text: any;
}
export function SmallBoxPicture ({headline, src, headlineText, text}: SmallBoxInterface) {
        return (
            <Wrapper>
                <PHead>
                <strong><VerticalLine>| &nbsp;</VerticalLine>{headline}</strong>
                </PHead>
                <ImageDiv>
                    <Img src={src} alt="BildOrgelentdeckertage" />
                </ImageDiv>
                <P>
                    <strong>{headlineText}</strong> <br />
                    {text}
                </P>
            </Wrapper>
        );
    }
