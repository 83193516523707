import React, { Component } from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Kirchengemeinden } from "./pages/Kirchengemeinden";
import { OnlineGottesdienste } from "./pages/OnlineGottesdienste";
import { Kontakt } from "./pages/Kontakt";
import { Pictures } from "./pages/Pictures";
const RouteElements = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={<Home title="Organist Jan Semrau - Home" />}
            />
            <Route
                path="/about"
                element={<About title="Organist Jan Semrau - Über mich" />}
            />
            <Route
                path="/kirchengemeinden"
                element={
                    <Kirchengemeinden title="Organist Jan Semrau - Kirchengemeinden" />
                }
            />
            <Route
                path="/online-gottesdienste"
                element={
                    <OnlineGottesdienste title="Organist Jan Semrau - Online-Gottesdienste" />
                }
            />
            <Route
                path="/kontakt"
                element={<Kontakt title="Organist Jan Semrau - Kontakt" />}
            />
            <Route
                path="/pictures"
                element={<Pictures title="Organist Jan Semrau - Bilder" />}
            />
        </Routes>
    );
};
export default RouteElements;
