interface PagerInterface{
    setMonth: (month: number) => unknown;
    setYear: (year: number) => unknown;
    month: number;
    year: number;
}

export const pager = ({setMonth, setYear, month, year}:PagerInterface) => {
    const backward = () => {
        if (month > 1) {
            setMonth(month - 1);
        } else {
            setMonth(month + 11);
            setYear(year - 1);
        }
    };

    const forward = () => {
        if (month < 12) {
            setMonth(month + 1);
        } else {
            setMonth(month - 11);
            setYear(year + 1);
        }
    };

    return {
        backward,
        forward
    };
};
