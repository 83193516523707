import React, { Component }  from 'react';
import styled from "styled-components";
import { P, PHead} from "./BigBox";
import { SmallBoxInterface, Wrapper } from "./SmallBoxPicture";
import { VerticalLine } from "./BigBox";

const VideoContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    margin-left: 1em;
    margin-right: 1em;
`;
const IFrame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
const InnerWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
flex 1 0 auto;
`;

export function SmallBoxVideo ({headline, src, headlineText, text}:SmallBoxInterface){
        return (
            <Wrapper>
                <PHead>
                <strong><VerticalLine>| &nbsp;</VerticalLine>{headline}</strong>
                </PHead>
                <InnerWrapper>
                <VideoContainer>
                    <IFrame
                        title="Ein Video"
                        id="NeuesWerk"
                        allowFullScreen={true}
                        src={src}
                    ></IFrame>
                </VideoContainer>
                <P>
                    <strong>{headlineText}</strong> <br />
                    {text}
                    </P>
                    </InnerWrapper>
            </Wrapper>
        );
    }
