import React from 'react';
import { BigBox } from "../components/BoxComponents/BigBox";
import { useEffect } from "react";
import { Wrapper } from "./Home";
import BildJan from "../pictures/Jan/min_IMG_1171big.jpg";

interface AboutInterface {
    title: string;
}

export function About({title}:AboutInterface) {
    useEffect(() => {
        document.title = title;
    }, []);
    return (
        <Wrapper>
            <BigBox
                headline="Über Mich"
                headlineText="Über D-Organist Jan Semrau"
                text={<>
                    <p>
                        Mein Name ist Jan Semrau, ich bin 21 Jahre alt und komme
                        aus dem schönen Dorf Wipshausen in der Gemeinde
                        Edemissen.
                    </p>
                    <p>
                        Mit Musik kam ich bereits früh in Berührung. Schon im
                        Kindergarten hat mir Musik machen Spaß gemacht. In der
                        Grundschule fing ich an, Gitarre zu spielen. Die
                        Barré-Griffe haben mir jedoch die Lust am Gitarre
                        Spielen verdorben. Weiter ging es mit Keyboard, was ich
                        jedoch auch nicht lange gespielt habe. <br />
                        In der 5. Klasse war ich in der "Bläserklasse" und fing
                        an, Trompete zu spielen. Dies habe ich sehr gerne getan,
                        doch nach einem Schulwechsel zur 6. Klasse habe ich das
                        Instrument nicht weiter gespielt.
                    </p>
                    <p>
                        2013 ging es dann an die Orgel! Im Zuge des
                        Konfirmandenunterrichts besuchte ich regelmäßig
                        Gottesdienste und der Klang der Orgel faszinierte mich.
                        Sie war schon damals für mich die "Königin der
                        Instrumente"! Ich fragte Pastorin Seelenbinder aus
                        Wipshausen, bei wem ich Orgelunterricht nehmen könne. So
                        landete ich letztendlich bei Herrn Gürke, der in den
                        Gemeinden Eddesse-Abbensen-Oelerse regelmäßig die Orgel
                        spielte und auch heute immer noch spielt.
                    </p>
                    <p>
                        Ich nahm bei ihm Unterricht und spielte am 2. Advent
                        2013 das erste Mal in einem Gottesdienst. Im Laufe der
                        nächsten Monate übernahm ich immer größere Teile der
                        Gottesdienste und spielte bald alleine die Orgel im
                        Gottesdienst.
                    </p>
                    <p>
                        Im August 2018 legte ich die Orgel D-Prüfung bei
                        Kreiskantor Nigbur aus Gifhorn und Kreiskantor
                        Manderscheid aus Wolfsburg ab. Nach kurzer Pause ging es
                        nun im September 2021 mit dem regionalen C-Kurs in Celle
                        los. 2023 werde ich die C-Kirchenmusikprüfung bestehend
                        aus der Orgel- und der Chorleiterprüfung absolvieren.
                    </p>
                </>
                }
                image={BildJan}
            />
        </Wrapper>
    );
}
