import React, { Component }  from 'react';
import styled from "styled-components";
import "./Footer.css";

const FlexboxContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: white;
    margin-top: 2rem;
    bottom: 0px;
    margin-bottom: 1.14%;
    @media screen and (max-width: 800px) {
        display: flex;
        max-width: 100%;
        flex-basis: 100%;
        bottom: 0px;
        align-items: center;
    }
`;
const FlexboxItem = styled.div`
    height: 10%;
    width: 50%;
    background-color: white;
    color: #014153;
    border: hidden;
    text-decoration: none;
    text-align: center;
    padding-bottom: 1rem;
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    max-width: 30%;
    @media screen and (max-width: 800px) {
        margin-top: 1rem;
        flex-basis: 100%;
        max-width: 100%;
        height: auto;
        margin-right: 0;
    }
`;
export const FooterBottom = styled.div`
    text-align: center;
    bottom: 0;
    width: 100%;
`;
const FooterTextA = styled.a`
    font-family: Arial, Helvetica, sans-serif;
    line-height: 50%;
    font-size: 1rem;
`;
const FooterTextP = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    line-height: 50%;
    font-size: 1rem;
`;
export const FooterTextBottom = styled(FooterTextP)`
    font-size: 0.5rem;
    padding-bottom: 0.5rem;
`;
const Headline = styled.h1`
    font-family: Arial, Helvetica, sans-serif;
    color: #ed6a4e;
`;

export function Footer() {
    return (
        <>
            <FlexboxContainer>
                <FlexboxItem>
                    <Headline>SOCIAL MEDIA</Headline>
                    <FooterTextA
                        href="https://www.facebook.com/semmelsemrau"
                        target="_blank"
                    >
                        <i className="fa fa-facebook"></i>
                    </FooterTextA>
                    <FooterTextA
                        href="https://www.instagram.com/jan.semrau"
                        target="_blank"
                    >
                        <i className="fa fa-instagram"></i>
                    </FooterTextA>
                    <FooterTextA
                        href="https://www.youtube.com/channel/UCgOYsFTC2y6HhOmBrGqjrIg"
                        target="_blank"
                    >
                        <i className="fa fa-youtube"></i>
                    </FooterTextA>
                </FlexboxItem>
                <FlexboxItem>
                    <Headline>KONTAKT</Headline>
                    <FooterTextP>janmalte2000@web.de</FooterTextP>
                </FlexboxItem>
                <FlexboxItem>
                    <Headline>ORGELENTDECKERTAGE</Headline>
                    <FooterTextA
                        href="http://orgelentdecker.de"
                        target="_blank"
                    >
                        Alle Infos gibt es hier
                    </FooterTextA>
                </FlexboxItem>
            </FlexboxContainer>
            <FooterBottom>
                <FooterTextBottom>
                    All Rights reserved by &copy;Jan Semrau
                </FooterTextBottom>
            </FooterBottom>
        </>
    );
}
