import React, { Component }  from 'react';
import styled from "styled-components";
import { ImageDiv } from "./SmallBoxPicture";

export const Wrapper = styled.div`
    border-top: solid 5px #ed6a4e;
    background-color: white;
    margin-top: 2rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
`;

export const Line = styled.div`
&:after {
        content: "";
        display: block;
        width: 7.5rem;
        height: 0.25rem;
        margin-top: 2rem;
        background: #ed6a4e;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  }
`
export const VerticalLine = styled.span`
    color:#ed6a4e;
    font-size: 1.6rem;
`;

export const PHead = styled.p`
    font-size: 1.5em;
    color: #014153;
    padding-left: 1rem;
    text-align: left;
`;
export const P = styled.p`
    color: #014153;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: left;
    font-size: 1em;
`;
export const Img = styled.img`
    object-fit: contain;
    width: 100%;
`;

interface BigBoxProps {
    headline: string;
    headlineText?: string;
    text: any;
    image: string;
    children?: HTMLElement;
}

export function BigBox({ headline, headlineText, text, image, children }:BigBoxProps) {
    return (
        <>
        <Wrapper>
            <PHead>
                <strong><VerticalLine>| &nbsp;</VerticalLine>{headline}</strong>
            </PHead>
            <ImageDiv>
                <Img src={image} alt="Bild1" />{" "}
            </ImageDiv>
            <P>
                {headlineText !== undefined && (<>
                    <strong>{headlineText}</strong> <br />
                    </>
                )}
                {text}
            </P>
            {children}
            </Wrapper>
            <Line ></Line>
            </>
    );
}

