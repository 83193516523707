import React from 'react';
import { useEffect } from "react";
import {PHead} from "../components/BoxComponents/BigBox";
import {
    InnerWrapper,
    Wrapper as OuterWrapper,
} from "./Home";
import styled from "styled-components";
import { SmallBoxLink } from "../components/BoxComponents/SmallBoxLink";
import { FooterBottom, FooterTextBottom } from "../components/Footer/Footer";
import {VerticalLine} from "../components/BoxComponents/BigBox"
import Church from "../pictures/church.png";
import Rueper from "../pictures/Kirchengemeinden/rueper-kachel.jpg";
import Solschen from "../pictures/Kirchengemeinden/solschen-kachel.jpg";
import Stedum from "../pictures/Kirchengemeinden/stedum-kachel.jpg";
import GroßBuelten from "../pictures/Kirchengemeinden/groß-buelten-kachel.jpg";
import Buelten from "../pictures/Kirchengemeinden/buelten-kachel.jpg";
import Westerbeck from "../pictures/Kirchengemeinden/westerbeck-kachel.jpg"
import Wipshausen from "../pictures/Kirchengemeinden/wipshausen-kachel.jpg"
import Hillerse from "../pictures/Kirchengemeinden/hillerse-kachel.jpg"
import Didderse from "../pictures/Kirchengemeinden/didderse-kachel.jpg"

const HeaderBeitrag = styled.p`
    font-size: 1rem;
    font-weight: 800;
    color: orange;
    background-color: white;
    border-top: solid 5px #ed6a4e;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 0;
`;


export const StandardText = styled.a`
`;
interface KirchengemeindenInterface {
    title: string;
}

export function Kirchengemeinden({title}:KirchengemeindenInterface) {
    useEffect(() => {
        document.title = title;
    }, []);
    return (
        <OuterWrapper>
            <HeaderBeitrag>
                <PHead>
                    <strong><VerticalLine>|&nbsp;</VerticalLine>Gemeinden</strong> <br />
                    &nbsp; Kirchenkreis Peine
                </PHead>
            </HeaderBeitrag>
            <InnerWrapper>
                    <SmallBoxLink
                        title="St. Sebastian Wipshausen"
                        picture={Wipshausen}
                        link="https://www.kirche-peine.de/gemeinden/region-nord/wipshausen/"
                    ></SmallBoxLink>
                    <SmallBoxLink
                        title="St. Jerusalem Rüper"
                        picture={Rueper}
                        link="https://www.kirche-peine.de/gemeinden/region-nord/rueper/"
                    ></SmallBoxLink>
            </InnerWrapper>
            <InnerWrapper>
                    <SmallBoxLink
                        title="St. Bernward Eddesse"
                        picture={Church}
                        link="https://www.kirche-peine.de/gemeinden/region-nord/eddesse/"
                    ></SmallBoxLink>
                    <SmallBoxLink
                        title="Christuskirche Abbensen"
                        picture={Church}
                        link="https://www.kirche-peine.de/gemeinden/region-nord/abbensen/"
                    ></SmallBoxLink>
            </InnerWrapper>
            <InnerWrapper>
                    <SmallBoxLink
                        title="Oelerse"
                        picture={Church}
                        link="https://www.kirche-peine.de/gemeinden/region-nord/oelerse/"
                    ></SmallBoxLink>
                    <SmallBoxLink
                        title="Martin-Luther Edemissen"
                        picture={Church}
                        link="https://www.kirche-peine.de/gemeinden/region-nord/edemissen/"
                    ></SmallBoxLink>
            </InnerWrapper>
            <InnerWrapper>
                    <SmallBoxLink
                        title="Markuskirche Bülten"
                        picture={Buelten}
                        link="https://www.kirche-peine.de/gemeinden/region-suedwest/buelten/"
                    ></SmallBoxLink>
                    <SmallBoxLink
                        title="Heilig Geist Gr. Bülten"
                        picture={GroßBuelten}
                        link="https://www.kirche-peine.de/gemeinden/region-suedwest/gr-buelten/"
                    ></SmallBoxLink>
            </InnerWrapper>
            <InnerWrapper>
                    <SmallBoxLink
                        title="St. Pancratii Solschen"
                        picture={Solschen}
                        link="https://www.kirche-peine.de/gemeinden/region-suedwest/solschen"
                    ></SmallBoxLink>
                    <SmallBoxLink
                        title="Sankt Johannes Stedum"
                        picture={Stedum}
                        link="https://www.kirche-peine.de/gemeinden/region-suedwest/stedum/"
                    ></SmallBoxLink>
            </InnerWrapper>
            <InnerWrapper>
                    <SmallBoxLink
                        title="St. Johannis Telgte"
                        picture={Church}
                        link="https://www.kirche-peine.de/gemeinden/region-stadt/st-johannis-peine/"
                    ></SmallBoxLink>
                    <SmallBoxLink
                        title="Malerhofkapelle Voigtholz"
                        picture={Church}
                        link="https://www.kirche-peine.de/gemeinden/region-nord/voigtholz/"
                    ></SmallBoxLink>
            </InnerWrapper>
            <HeaderBeitrag>
                <PHead>&nbsp; Kirchenkreis Gifhorn</PHead>
            </HeaderBeitrag>
            <InnerWrapper>
                    <SmallBoxLink
                        title="St. Viti-Kirche Didderse"
                        picture={Didderse}
                        link="https://www.kirche-didderse-hillerse-neubrueck.de"
                    ></SmallBoxLink>
                    <SmallBoxLink
                        title="St. Viti-Kirche Hillerse"
                        picture={Hillerse}
                        link="https://www.kirche-didderse-hillerse-neubrueck.de"
                    ></SmallBoxLink>
            </InnerWrapper>
            <InnerWrapper>
                    <SmallBoxLink
                        title="Zum guten Hirten Westerbeck"
                        picture={Westerbeck}
                        link="https://zumgutenhirten.wir-e.de/online-gottesdienste"
                    ></SmallBoxLink>
                    <SmallBoxLink
                        title="St. Marien Isenbüttel"
                        picture={Church}
                        link="http://www.kirche-isenbuettel.de"
                    ></SmallBoxLink>
            </InnerWrapper>
            <FooterBottom>
                <FooterTextBottom>
                    <StandardText
                        href="https://www.flaticon.com/authors/pixel-perfect"
                        title="Pixel perfect"
                    >
                        Icons made by Pixel perfect from www.flaticon.com
                    </StandardText>
                </FooterTextBottom>
            </FooterBottom>
        </OuterWrapper>
    );
}
