import React from 'react';
import { BigBox } from "../components/BoxComponents/BigBox";
import { useEffect } from "react";
import  BildOrgel  from "../pictures/Equipment/IMG_6273.jpeg";
import { Wrapper } from "./Home";
import { Cards} from "../components/Cards/Cards"

interface OnlineGottesdienste {
    title: string;
}

export function OnlineGottesdienste({title}:OnlineGottesdienste) {
    useEffect(() => {
        document.title = title;
    }, []);
    return (
        <Wrapper>
            <BigBox
                headline="Online-Gottesdienste"
                text={<>
                    <p>
                        Durch die Corona-Pandemie und den damit verbundenen Ausfall von Präsenzsgottesdiensten wurden einige Gottesdienste online gehalten. <br />
                        Auch ich durfte schon Online-Gottesdienste mit meiner Orgel von zuhause aus begleiten!
                    </p>
                      <p>
                            Schreiben Sie mir eine&nbsp;
                            <a
                            href="mailto:janmalte2000@web.de"
                        >
                        Mail
                            </a>
                            , wenn ich auch für Sie im Online-Gottesdienst spielen darf.
                    </p>
                </>
                }
                image={BildOrgel}
            />
           <Cards></Cards>
        </Wrapper>
    );
}