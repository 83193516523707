import React, { Component }  from 'react';
import { Img } from "./BigBox";
import { ImageDiv, Wrapper } from "./SmallBoxPicture";
import styled from "styled-components";

const A = styled.a`
    font-size: 1em;
    color: #014153;
    text-decoration: none;
`;

export const StandardText = styled.span`
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
`;

interface SmallBoxLinkInterface{
    link: string;
    title: string;
    picture: string;
}

export function SmallBoxLink ({link, title, picture}:SmallBoxLinkInterface){

        return (
            <Wrapper>
                <A
                    href={link}
                    title={title}
                    target="_blank"
                >
                    <ImageDiv>
                        <Img src={picture} alt={title} />
                    </ImageDiv>
                    <StandardText>{title}</StandardText>
                </A>
            </Wrapper>
        );
    }

