import React, { Component } from "react";
import "./Parts.css";
export const Parts = () => {
    return (
        <section class="parts">
            <div class="container">
                <h2 class="heading heading--centered">Was macht mich aus?</h2>

                <div class="parts__items-outer">
                    <div class="parts__items">
                        <p class="parts__item">
                            <div class="parts__item-icon-container">
                                <p
                                    class="parts__item-icon"
                                    viewBox="0 0 1024 1024"
                                >
                                    2013
                                </p>
                            </div>
                            <h4 class="parts__item-heading">
                                mit dem Orgelspiel begonnen
                            </h4>
                        </p>
                        <p class="parts__item">
                            <div class="parts__item-icon-container">
                                <p
                                    class="parts__item-icon"
                                    viewBox="0 0 1024 1024"
                                >
                                    2018
                                </p>
                            </div>
                            <h4 class="parts__item-heading">
                                D-Prüfung absolviert
                            </h4>
                        </p>
                        <p class="parts__item">
                            <div class="parts__item-icon-container">
                                <p
                                    class="parts__item-icon"
                                    viewBox="0 0 1024 1024"
                                >
                                    2023
                                </p>
                            </div>
                            <h4 class="parts__item-heading">
                                C-Kirchenmusikprüfung
                            </h4>
                        </p>
                        <p class="parts__item">
                            <div class="parts__item-icon-container">
                                <p class="parts__item-icon">&gt; 100</p>
                            </div>
                            <h4 class="parts__item-heading">
                                Stücke im Repertoire sowie unzählige Choräle und
                                Lieder
                            </h4>
                        </p>
                        <p class="parts__item">
                            <div class="parts__item-icon-container">
                                <p
                                    class="parts__item-icon"
                                    viewBox="0 0 1024 1024"
                                >
                                    3
                                </p>
                            </div>
                            <h4 class="parts__item-heading">
                                Musikinstrumente - Orgel, Klavier, Gitarre
                            </h4>
                        </p>
                    </div>
                </div>

                <p class="parts__paragraph">
                    Kommen Sie bei Fragen auf mich zu. Liedwünsche sind oft
                    machbar. Ich versuche umzusetzen was mir möglich ist!
                </p>
            </div>
        </section>
    );
};
