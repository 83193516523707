import { gapi } from "gapi-script";
import axios from "axios";

//require("dotenv").config();

const apiKey = "AIzaSyCeqQ1JRFd4OVs-lztI9CPP8TLVIsVhBxg";
const apiPath =
    "https://www.googleapis.com/calendar/v3/calendars/jmcsemrau@gmail.com/events";
export const getEvents = (setEventObject) => {
    function start() {
        gapi.client
            .init({
                apiKey: apiKey,
            })
            .then(function () {
                return gapi.client.request({
                    path: apiPath,
                    params: { maxResults: 2500 },
                });
            })
            .then((response) => {
                let events = response.result.items;
                setEventObject(events);
            });
    }
    gapi.load("client", start);
};

export const getLatestVideo = async (setYouTubeData) => {
    try {
        const results = await axios.get(
            `https://www.jan-semrau.de/videoData.json`
        );
        await setYouTubeData({
            videoData: {
                title: results.data.items[0].snippet.title,
                description: results.data.items[0].snippet.description,
            },
            src: results.data.items[0].id.videoId,
        });
    } catch {
        const results = await axios.get(`https://jan-semrau.de/videoData.json`);
        await setYouTubeData({
            videoData: {
                title: results.data.items[0].snippet.title,
                description: results.data.items[0].snippet.description,
            },
            src: results.data.items[0].id.videoId,
        });
    }
};
