import React, { Component }  from 'react';
import { useState, useEffect } from "react";
import styled from "styled-components";
import { CalendarEntry } from "./CalendarEntry";
import { getEvents } from "../Service/API";
import { pager as pagerFunction } from "../Service/Pager";
import { VerticalLine, Line } from "../BoxComponents/BigBox";


export const Wrapper = styled.div`
    border-top: solid 5px #ed6a4e;
    background-color: white;
    margin-bottom: 0;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;
`;
export const PHead = styled.p`
    font-size: 1.5em;
    color: #014153;
    padding-left: 1rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
`;
export const Button = styled.button`
    font-size: 1em;
    color: white;
    background-color: #014153;
    border: none;
    margin-left: 1em;
    text-align: center;
    @media (max-width: 800px) {
        margin-top: 0.5rem;
        font-size: 1em;
    }
`;

export const ButtonWrapper = styled.div`
    margin-right: 1em;
`;

export const Calendar = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const [month, setMonth] = useState<number>(currentMonth + 1);
    const [year, setYear] = useState<number>(currentYear);
    const [monthName, setMonthName] = useState<string>("Januar");
    const [eventObject, setEventObject] = useState<Array<string>>([]);
    const [pager, setPager] = useState(pagerFunction({ setMonth, setYear, month, year }));

    let list = [];
    let monthlyEntries = [];
    let monthString = "";
    let date = "";
    let newDate = "";
    let time = "";
    let dateTime = "";
    let dayString = "";
    let isFlagged = false;
    let flagged = false;
    let inFuture = false;

    useEffect(() => {
        getEvents(setEventObject);
        switch (month) {
            case 1:
                setMonthName("Januar");
                break;
            case 2:
                setMonthName("Februar");
                break;
            case 3:
                setMonthName("März");
                break;
            case 4:
                setMonthName("April");
                break;
            case 5:
                setMonthName("Mai");
                break;
            case 6:
                setMonthName("Juni");
                break;
            case 7:
                setMonthName("Juli");
                break;
            case 8:
                setMonthName("August");
                break;
            case 9:
                setMonthName("September");
                break;
            case 10:
                setMonthName("Oktober");
                break;
            case 11:
                setMonthName("November");
                break;
            case 12:
                setMonthName("Dezember");
                break;
            default:
                setMonthName("");
                break;
        }
            setPager(pagerFunction({ setMonth, setYear, month, year }));
    }, [month, year]);

    if (month < 10) {
        monthString = month.toString();
        monthString = "0" + monthString;
    } else {
        monthString = month.toString();
    }

    monthlyEntries = eventObject.filter(
        (element:any) =>
            element.start.dateTime?.substring(5, 7) === monthString &&
            element.start.dateTime?.substring(0, 4) === year.toString()
    );

    function compare(a:any, b:any) {
        if (a.start.dateTime < b.start.dateTime) {
            return -1;
        }
        if (a.start.dateTime > b.start.dateTime) {
            return 1;
        }
        return 0;
    }

    monthlyEntries.sort(compare);
    if (monthlyEntries.length !== 0) {
        let currentDay = currentDate.getDate();
        if (currentDay < 10) {
            dayString = currentDay.toString();
            dayString = "0" + dayString;
        } else {
            dayString = currentDay.toString();
        }

        monthlyEntries.forEach((element:any) => {
            date = element.start.dateTime.substring(0, 10).split("-");
            newDate = `${date[2]}.${date[1]}.${date[0]}`;

            if (month === currentMonth + 1) {
                if (date[2] >= dayString && flagged === false) {
                    isFlagged = true;
                    flagged = true;
                    inFuture = true;
                }
            }
            if (month > currentMonth + 1 && year >= currentYear) {
                inFuture = true;
            }
            if (year > currentYear) {
                inFuture = true;
            }

            time = element.start.dateTime.substring(11, 16);
            dateTime = `${newDate}, ${time} Uhr   `;

            let city = element.summary.split(" ");
            let type = city[0];
            city.shift();
            city = city.join(" ");

            list.push(
                <CalendarEntry
                    key={dateTime}
                    dateTime={dateTime}
                    city={city}
                    type={type}
                    flagged={isFlagged}
                    inFuture={inFuture}
                />
            );
            isFlagged = false;
        });
    } else {
        list.push(
            <CalendarEntry
                dateTime={"-"}
                city={"-"}
                type={"-"}
                flagged={isFlagged}
                inFuture={inFuture}
            />
        );
    }

    return (
        <>
        <Wrapper>
            <PHead>
                
                <strong><VerticalLine>| &nbsp;</VerticalLine>{`Meine Gottesdienste im ${monthName} ${year}`}</strong>
                <ButtonWrapper>
                    <Button onClick={pager.backward}>&lt;</Button>
                    <Button onClick={pager.forward}>&gt;</Button>
                </ButtonWrapper>
            </PHead>
            {list}
        </Wrapper>
                    <Line></Line>
        </>
    );
}
