import React from 'react';
import { BigBox } from "../components/BoxComponents/BigBox";
import { useEffect } from "react";
import BildJan from "../pictures/Jan/IMG_1130.jpeg";
import { Wrapper } from "./Home";

interface KontaktInterface {
    title: string;
}

export function Kontakt({title}:KontaktInterface) {
    useEffect(() => {
        document.title = title;
    }, []);
    return (
        <Wrapper>
            <BigBox
                headline="KONTAKTANFRAGE"
                text={
                    <p>
                        Haben Sie Fragen, Anregungen, Wünsche oder möchten mich
                        für Ihre Kirchengemeinde anfragen? <br />
                        Dann schreiben Sie mir gerne eine&nbsp;
                        <a
                            href="mailto:janmalte2000@web.de"
                        >
                        Mail
                            </a>
                            .
                    </p>
                }
                image={BildJan}
            />
        </Wrapper>
    );
}