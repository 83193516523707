import React, { Component }  from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
    border-bottom: solid 1px gray;
    background-color: white;
    margin: 0 1em 1em 1em;
`;

export const CalendarEntryValue = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: left;
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    color: #014153;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

export const EntryDiv = styled.div`
    display: flex;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

export const Komma = styled.span`
    @media screen and (max-width: 800px) {
        color: transparent;
    }
`;

interface CalendarEntryProps{
    dateTime: string;
    city: string;
    type: string;
    flagged?: boolean;
    inFuture: boolean;
}

export function CalendarEntry({ dateTime, city, type, flagged, inFuture }:CalendarEntryProps) {
    return (
        <>
            {flagged ? (
                <Wrapper>
                    <CalendarEntryValue
                        style={{
                            fontSize: "1.2em",
                        }}
                    >
                        <EntryDiv>
                            <div>
                                <strong style={{ color: "#ed6a4e" }}>
                                    {`>`}&nbsp;
                                </strong>
                                <strong>{`${dateTime}`}</strong>
                                <Komma>{`:`}&nbsp;</Komma>
                                <strong>{`${city}`}</strong>
                            </div>
                        </EntryDiv>
                        <EntryDiv>
                            <div>
                                <strong>{`${type}`}</strong>
                                <strong style={{ color: "red" }}>
                                    &nbsp;{`<`}
                                </strong>
                            </div>
                        </EntryDiv>
                    </CalendarEntryValue>
                </Wrapper>
            ) : (
                <Wrapper>
                    {inFuture === true && (
                        <CalendarEntryValue>
                            <EntryDiv>
                                <strong>{`${dateTime}`}</strong>
                                <Komma>{`:`}&nbsp;</Komma>
                                <strong>{`${city}`}</strong>
                            </EntryDiv>
                            <strong>{`${type}`}</strong>
                        </CalendarEntryValue>
                    )}{inFuture === false && (
                        <CalendarEntryValue style={{color: "gray"}}>
                            <EntryDiv>
                                <strong>{`${dateTime}`}</strong>
                                <Komma>{`:`}&nbsp;</Komma>
                                <strong>{`${city}`}</strong>
                            </EntryDiv>
                            <strong>{`${type}`}</strong>
                        </CalendarEntryValue>
                    )}
                </Wrapper>
            )}
        </>
    );
}
