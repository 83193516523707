import React, { Component } from "react";
import "./Cards.css";
import Bild1 from "../../pictures/Equipment/min_IMG_5245.jpg";
import Bild2 from "../../pictures/Equipment/min_IMG_6278.jpg";
import Bild3 from "../../pictures/Equipment/min_IMG_6280.jpg";
export const Cards = () => {
    return (
        <section class="adventures">
            <div class="container">
                <h2 class="heading heading--centered">
                    <span class="heading__pre-text">Weitere Angebote</span>
                    für Ihren Gottesdienst
                </h2>
                <div class="adventures__card-container">
                    <div class="adventures__card">
                        <img
                            class="adventures__card-img"
                            src={Bild1}
                            alt="Hängeschaukel"
                        />
                        <h4 class="adventures__card-heading">Mobile Orgel</h4>
                        <p class="adventures__card-date">
                            z.B. in Altenheimen oder Open Air
                            <br />
                            <span class="text-primary">_____</span>
                        </p>
                        <a
                            href="mailto:janmalte2000@web.de"
                            class="button button--margin-bottom"
                        >
                            Anfragen
                        </a>
                    </div>
                    <div class="adventures__card">
                        <img
                            class="adventures__card-img"
                            src={Bild2}
                            alt="Hängeschaukel"
                        />
                        <h4 class="adventures__card-heading">
                            E-Piano mit Gesang
                        </h4>
                        <p class="adventures__card-date">
                            z.B auf Taufen oder Trauerfeiern <br />
                            <span class="text-primary">_____</span>
                        </p>
                        <a
                            href="mailto:janmalte2000@web.de"
                            class="button button--margin-bottom"
                        >
                            Anfragen
                        </a>
                    </div>
                    <div class="adventures__card">
                        <img
                            class="adventures__card-img"
                            src={Bild3}
                            alt="Hängeschaukel"
                        />
                        <h4 class="adventures__card-heading">Aufnahmen</h4>
                        <p class="adventures__card-date">
                            Von Ihrer Orgel oder ihres Chores <br />
                            <span class="text-primary">_____</span>
                        </p>
                        <a
                            href="mailto:janmalte2000@web.de"
                            class="button button--margin-bottom"
                        >
                            Anfragen
                        </a>
                    </div>
                </div>
                <h4 class="adventures__card-heading">
                    Ich nehme auch Stücke für Ihren Gottesdienst auf
                </h4>
            </div>
        </section>
    );
};
