import React, { Component }  from 'react';
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../pictures/Logo/logo.png"

const HeaderStyle = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #014153;
    @media (max-width: 800px) {
        justify-content: space-between;
        background-color: #014153;
        background-image: none;
    }
`;


const Wrapper = styled.div`
    margin: 0;
    @media (min-width: 800px) {
    }
`;
const HeaderButton = styled.div`
    background-color: #014153;
`;

const Icon = styled.button`
    display: none;
    @media (max-width: 800px) {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        color: white;
    }
`;

const Img = styled.img`
    width: 15rem;
    height: auto;
    padding: 1rem;
    @media (max-width: 800px) {
        width: auto;
        height: 8rem;
    }
`;
const ImgMobile = styled.img`
    display: none;
    padding: 1rem;
    width: 15rem;
    height: auto;
    @media (max-width: 800px) {
                        display: flex;
        width: auto;
        height: 8rem;
    }
`;

const NavContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0 1rem;
    width: fit-content;
    @media (max-width: 800px) {
        font-size: 1rem;
        display: block;
        padding: 0.5rem 1rem;
        width: 100%;
    }
        &:hover {
        color: white;
        background-color: #ed6a4e;
    }
`;

const Links = styled.div`
    font-size: 5rem;
    @media (max-width: 800px) {
        display: none;
        background-color: #014153;
    }
`;

const NavbarText = styled(Link)`
    color: white;
    width: fit-content;
    text-decoration: none;
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 800px) {
        font-size: 1rem;
        display: block;
        width: 100%;
        height: fit-content;
    }
    &:hover {
        color: white;
        background-color: #ed6a4e;
    }
`;

export const Header = () => {
    const [isMobileHeader, setIsMobileHeader] = useState(false);

    return (
        <Wrapper>
            <Links>
                <HeaderStyle>
                    <NavbarText to="/"><Img src={Logo} alt="Organist Jan Semrau Logo"></Img></NavbarText>
                    <NavContainer>
                        <NavbarText to="/about">Über mich</NavbarText>
                    </NavContainer>
                    <NavContainer>
                    <NavbarText to="/kirchengemeinden">
                        Kirchengemeinden
                        </NavbarText>
                    </NavContainer>
                    <NavContainer>
                    <NavbarText to="/online-gottesdienste">
                        Online-Gottesdienste
                        </NavbarText>
                    </NavContainer>
                    <NavContainer>
                        <NavbarText to="/kontakt">Kontakt</NavbarText>
                    </NavContainer>
                    <NavContainer>
                        <NavbarText to="/pictures">Bilder</NavbarText>
                    </NavContainer>
                </HeaderStyle>
            </Links>
            <HeaderButton>
                <HeaderStyle>
                    <ImgMobile onClick={() => setIsMobileHeader(!isMobileHeader)} src={Logo} alt="Organist Jan Semrau Logo"></ImgMobile>
                    <Icon name="burger" onClick={() => setIsMobileHeader(!isMobileHeader)}>
                        <i className="fa fa-bars"></i>
                    </Icon>
                </HeaderStyle>
                {isMobileHeader && (
                    <div>
                    <NavContainer>
                        <NavbarText
                            to="/"
                            onClick={() => setIsMobileHeader(!isMobileHeader)}
                        >
                            Home
                            </NavbarText>
                    </NavContainer>
                    <NavContainer>
                        <NavbarText
                            to="/about"
                            onClick={() => setIsMobileHeader(!isMobileHeader)}
                        >
                            Über mich
                            </NavbarText>
                        </NavContainer>
                        <NavContainer>
                        <NavbarText
                            to="/kirchengemeinden"
                            onClick={() => setIsMobileHeader(!isMobileHeader)}
                        >
                            Kirchengemeinden
                            </NavbarText>
                        </NavContainer>
                        <NavContainer>
                        <NavbarText
                            to="/online-gottesdienste"
                            onClick={() => setIsMobileHeader(!isMobileHeader)}
                        >
                            Online-Gottesdienste
                            </NavbarText>
                        </NavContainer>
                        <NavContainer>
                        <NavbarText
                            to="/kontakt"
                            onClick={() => setIsMobileHeader(!isMobileHeader)}
                        >
                            Kontakt
                            </NavbarText>
                        </NavContainer>
                        <NavContainer>
                        <NavbarText
                            to="/pictures"
                            onClick={() => setIsMobileHeader(!isMobileHeader)}
                        >
                            Bilder
                            </NavbarText>
                        </NavContainer>
                    </div>
                )}
                </HeaderButton>
        </Wrapper>
    );
};
