import React, { useEffect }  from 'react';
import styled from "styled-components";
import Bild2 from "../pictures/Jan/IMG_1106.jpeg";
import Bild3 from "../pictures/Jan/IMG_1113.jpeg";
import Bild4 from "../pictures/Jan/IMG_1116.jpeg";
import Bild5 from "../pictures/Jan/IMG_1119.jpeg";
import Bild6 from "../pictures/Jan/IMG_1123.jpeg";
import Bild7 from "../pictures/Jan/IMG_1124.jpeg";
import Bild8 from "../pictures/Jan/IMG_1130.jpeg";
import Bild9 from "../pictures/Jan/IMG_1143.jpeg";
import Bild10 from "../pictures/Jan/IMG_1145.jpeg";
import Bild11 from "../pictures/Jan/IMG_1146.jpeg";
import Bild12 from "../pictures/Jan/IMG_1148.jpeg";
import Bild13 from "../pictures/Jan/IMG_1150.jpeg";
import Bild14 from "../pictures/Jan/IMG_1152.jpeg";
import Bild15 from "../pictures/Jan/IMG_1153.jpeg";
import Bild16 from "../pictures/Jan/IMG_1169.jpeg";
import Bild17 from "../pictures/Jan/IMG_1171.jpeg";
import Bild18 from "../pictures/Jan/IMG_1183.jpeg";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1em;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: solid 5px #ed6a4e;
    background-color: white;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    @media screen and (max-width: 800px) {
        margin-left: 10%;
        margin-right: 10%;
        flex-direction: column;
        padding-top: 0rem;
    }
`;

export const Img = styled.img`
    padding: 1rem;
    object-fit: contain;
    width: 48%;
    @media screen and (max-width: 800px) {
            width: 100%;
            padding: 1rem 1rem 0 1rem;
    }
`;

interface PicturesInterface {
    title: string;
}

export function Pictures({title}:PicturesInterface) {
    useEffect(() => {
        document.title = title;
    }, []);
    return (
        <Wrapper>
             
                <Img src={Bild2} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild3} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild4} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild5} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild6} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild7} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild8} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild9} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild10} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild11} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild12} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild13} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild14} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild15} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild16} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild17} alt="BildOrgelentdeckertage" />
             
             
                <Img src={Bild18} alt="BildOrgelentdeckertage" />
             
        </Wrapper>
    );
}
