import React from 'react';
import { useState, useEffect } from "react";
import { BigBox } from "../components/BoxComponents/BigBox";
import { Calendar } from "../components/Calendar/Calendar";
import { SmallBoxVideo } from "../components/BoxComponents/SmallBoxVideo";
import { SmallBoxPicture } from "../components/BoxComponents/SmallBoxPicture";
import {Parts } from "../components/Parts/Parts"
import styled from "styled-components";
import BildWuensche from "../pictures/Jan/IMG_1116.jpeg";
import BildJan from "../pictures/Jan/IMG_1171big.jpeg";
import { getLatestVideo } from "../components/Service/API";
import { Cards} from "../components/Cards/Cards"

export const Wrapper = styled.div`
display: grid;
grid-template-columns: 1fr
grid-template-rows: 1fr 1fr 1fr;
gap: 0 1rem;
margin-left: 10%; 
margin-right: 10%;
text-align: center;
`;

export const InnerWrapper = styled.div`
    margin: 0;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
        margin: 0;
        flex-direction: column;
    }
`;

export const ObjectWrapper = styled.div`
    flex: 1;
    border-top: solid 5px orange;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    background-color: white;
    margin-top: 1rem;
`;
export const ObjectWrapperLeft = styled(ObjectWrapper)`
    margin-right: 1em;
    @media screen and (max-width: 800px) {
        margin-right: 0;
    }
`;

export const ObjectWrapperRight = styled(ObjectWrapper)`
    margin-left: 1em;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 800px) {
        margin-left: 0;
    }
`;

interface HomeInterface {
    title: string;
}


export function Home({ title }: HomeInterface) {
    const [youTubeData, setYouTubeData] = useState<any>({ videoData:{ title: "", description: "" }, src: ""});
  useEffect(()=>{
    getLatestVideo(setYouTubeData)
      console.log(youTubeData)
  },[])
        document.title = title;
        return (
            <Wrapper>
                <BigBox
                    headline="Ich spiele für Sie!"
                    headlineText="Egal ob Gottesdienst online oder vor Ort, Taufe oder Trauerfeier"
                    text={<>
                        <p>
                            Sie suchen einen Organisten für Ihren Gottesdienst? <br />
                            Sie planen eine Taufe und suchen einen Organisten, der für Sie spielt? <br/>
                            Auch auf Trauerfeiern spiele ich für Sie!
                        </p>
                        <p>
                            Schreiben Sie mir eine&nbsp;
                            <a
                            href="mailto:janmalte2000@web.de"
                        >
                        Mail
                            </a>
                            .
                        </p>
                         <p>
                            Klangbeispiele gibt es auf meinem Kanal auf&nbsp;
                            <a
                            href="https://www.youtube.com/channel/UCgOYsFTC2y6HhOmBrGqjrIg"
                            target="_blank" rel="noreferrer"
                        >
                        YouTube
                            </a>
                            !
                        </p>
                        <p>
                            Infos zu Online-Gottesdiensten und Aufnahmemöglichkeiten gibt es&nbsp;
                            <a
                            href="https://www.jansemrau.de/#/online-gottesdienste"
                            target="_blank" rel="noreferrer"
                        >
                        hier
                            </a>
                            !
                        </p>
                      
                        </>
                    }
                    image={BildJan}
                />
                <InnerWrapper>
                        <SmallBoxVideo
                            headline="Neuerscheinung"
                            headlineText={youTubeData.videoData.title}
                            text={
                                <p>
                                    {youTubeData.videoData.description}
                                </p>    
                            }
                            src={`https://www.youtube.com/embed/${youTubeData.src}`}
                        />
                        <SmallBoxPicture
                            headline="Was möchten Sie hören?"
                            headlineText="Wünsche auf der Orgel"
                            text={
                                <p>
                                    Haben Sie ein Stück oder ein Lied, was Sie
                                    gerne auf der Orgel hören möchten? <br />
                                    Dann lassen Sie es mich gerne wissen! <br />
                                </p>
                            }
                            src={BildWuensche}
                        />
                </InnerWrapper>
                <Parts></Parts>
                <Calendar></Calendar>
                <Cards></Cards>
            </Wrapper>
        );
    }