import React from "react";
import { HashRouter } from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer/Footer";
import RouteElements from "./Routes"
import styled from "styled-components";

const Wrapper = styled.div`
    background-color: #f1f1f1;
`;

function App() {
    return (
        <Wrapper>
            <HashRouter>
                <Header />
                    <RouteElements></RouteElements>
                <Footer />
            </HashRouter>
        </Wrapper>
    );
}

export default App;
